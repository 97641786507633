.project-team{
    display: flex;
    align-items: center;
    gap: 4em;
    margin: 7em 0;
}

.project-team .team{
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
}

.project-team .member{
    padding: 1em 4em;
    border: 2px solid rgb(var(--project-color-rgb));
    border-radius: 200px;
    font-weight: 500;
    font-size: 1.2em;
    /* remove this two lines if neccessary */
    flex-grow: 1;
    text-align: center;
}


@media (max-width: 800px){
    .project-team{
        flex-direction: column;
        margin: 0;
    }
    .project-team .member{
        padding: 1em;
    }
    
}