.about-project{
    margin: 10em 0;
}

.about-project .grid{
    display: grid;
    grid-template: 1fr / 1fr 1fr;
    align-items: center;
    grid-gap: 2em;
}

.short-description-holder,
.project-ideas-holder{
    position: relative;
    display: flex;
}

.project-ideas{
    --side-padding: 48px;
    position: relative;
    border-radius: 3em;
    max-width: 640px;
    padding: 120px var(--side-padding);
    padding-left: calc(var(--side-padding) * 1.8);
    perspective: 1000px;
}

.project-ideas::before{
    content: "";
    position: absolute;
    background: rgba(var(--project-color-rgb), .15);
    transform: rotateY(-16deg);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 3em;
    z-index: -1;
}

.project-ideas .idea{
    margin-bottom: 2em;
}

.project-ideas .idea .header{
    display: flex;
    align-items: center;
}

.project-ideas .idea .header .circle{
    --size: 2em;
    width: var(--size);
    height: var(--size);
    background: rgb(var(--project-color-rgb));
    border-radius: 50%;
    display: flex;
    margin-right: 1em;
    flex-shrink: 0;
}

.project-ideas .idea .header .circle svg{
    display: block;
    margin: auto;
    font-size: 1.2em;
    color: #fff;
}


.project-ideas .idea .header .title{
    font-weight: 600;
    font-size: 1.4em;
}

.project-ideas .idea .body{
    margin-top: 5px;
    margin-left: calc(3em);
    opacity: .6;
}

.short-description-holder{
    position: relative;
}

.short-description h3{
    font-size: 1.5em;
    line-height: 1;
    font-weight: bold;
    color: rgb(var(--project-color-rgb));
    font-family: var(--popins);
}

.short-description h2{
    margin-bottom: 1em;
}

.short-description p{
    text-align: justify;
}

@media (max-width: 1100px){

    .about-project{
        margin: 4em 0;
    }

    .about-project .grid{
        display: flex;
        flex-direction: column-reverse;
        gap: 5em;
    }

    .project-ideas{
        --side-padding: 1.7em;
        padding: 5em var(--side-padding);
    }

    .project-ideas::before{
        transform: rotateX(-16deg);
    }
}