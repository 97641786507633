.mockup-image {
    opacity: 0;
    transition: opacity var(--fade-animation-duration) ease-in;
    --webkit-transition: opacity var(--fade-animation-duration) ease-in;
}

.mockup-image.fadein {
    opacity: 1;
}

.stack.project {
    padding: 0;
}

.stack.project .slider {
    /* height: 100vh; */
    border-radius: 0;
    margin: 0;
}
