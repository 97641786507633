.note-holder{
    position: absolute;
    left: 0;
    transform:translate(-50%, -50%);
    padding: 1.5625em;
    max-width: 50%;
    width: 22em;
    background: #fff;
    border-radius: 1em;
    box-shadow: 0 3px 11px rgb(53 49 72 / 9%);
    z-index: 1; /* Mockup image changing opacity makes it overlay itself over this if they have the same z-index. Emma fix */
}

.note mark{
    line-height: 1.7;
    padding: 0 .225em;
}

.note .note-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
}

.note .note-footer .heart-holder{
    display: flex;
    align-items: center;
}

.note .note-footer .heart-holder span{
    font-size: .8em;
    opacity: .8;
    margin-left: .4em;
}

.note .note-footer .book-info span{
    font-size: .8em;
    font-weight: 400;
}

.heart:hover{
    animation: 1s tilt-shaking infinite;
}

@keyframes tilt-shaking {
	0% { transform: rotate(0deg); }
	25% { transform: rotate(5deg); }
	50% { transform: rotate(0eg); }
	75% { transform: rotate(-5deg); }
	100% { transform: rotate(0deg); }
  }


@media (max-width: 1300px){
    .note-holder{
        display: none;
    }
}