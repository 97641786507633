footer{
    margin-top: 10em;
}

footer .grid{
    border-bottom: 1px solid rgba(11,30,91,.12);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2em;
}

footer .socials .holder{
    display: flex;
    align-items: center;
}

footer .socials .logo{
    margin-bottom: 1em;
    font-size: 1.5em;
}

footer .socials .holder .item{
    position: relative;
    font-size: 1.4em;
    border: 1px solid rgba(11,30,91,.12);
    border-radius: 1000px;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    overflow: hidden;
}

footer .socials .holder .item::after{
    content: "";
    position: absolute;
    inset: 0;
    margin: auto;
    height: 10px;
    width: 10px;
    background: var(--main-font-color);
    border-radius: 50%;
    z-index: -1;
    opacity: 0;
}

footer .socials .holder .item:hover{
    color: #fff;
}

footer .socials .holder .item:hover::after{
  transform: scale(10);
  opacity: 1;
  transition: 400ms ease-in-out;
}

footer .navigation{
    display: flex;
}

footer .navigation .column h3{
    margin-bottom: 1em;
}

footer .navigation .column{
    margin-right: 4em;
}

footer .navigation .column:last-child{
    margin-right: 0;
}

footer .navigation .column ul li{
    color: #4e50f4;
    margin-bottom: 1em;
}

footer .brief{
    padding: 2em;
}

footer .brief p{
    opacity: .8;
}

@media (max-width: 800px){
    footer .grid{
        flex-direction: column;
        align-items: flex-start;
        gap: 2em;
    }
    footer .brief p{
        font-size: 1em;
    }
}