.project-info{
    position: relative;
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    background: #fff;
    padding: 2em;
    border-radius: 2em;
    margin-top: -13em;
    gap: 2em;
}

.project-info .info{
    flex: 1;
    border-right: 2px dashed rgba(var(--primary-color-rgb), .1);
    padding: .5em 2em;
}

.project-info .info:last-child{
    border: none;
}

.project-info .info .title{
    font-weight: 500;
    display: block;
    margin-bottom: .5em;
    font-size: 1.1em;
}


@media (max-width: 1200px){
    .project-info{
        display: grid;
        grid-template: 1fr / 1fr 1fr;
        margin-top: -7em;
    }
}

@media (max-width: 800px){
    .project-info{
        display: flex;
        flex-direction: column;
    }

    .project-info .info{
        border-bottom: 2px dashed rgba(var(--primary-color-rgb), .1);
        border-right: none;
    }
}