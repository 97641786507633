.contact {
    display: grid;
    grid-template: 1fr / 1fr 1fr;
    background: var(--secondary-color-light);
    align-items: center;
    border-radius: 1000px;
    padding: 8em 0;
    margin-top: 18em;
    margin-bottom: 5em;
}

.contact .info {
    padding-left: 11em;
}

.contact .info h2 {
    margin-bottom: .5em;
}

.contact .info .items-holder {
    margin-top: 2em;
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    align-items: center;
}

.contact .info .items-holder .item {
    display: flex;
}

.contact .info .items-holder .item .left {
    margin: 5px 0;
    margin-right: .5em;
    padding-right: .5em;
    border-right: 2px solid rgba(11,30,91,.12);
    display: flex;
    align-content: center;
}

.contact .info .items-holder .item .left svg{
    font-size: 2.2em;
    color: var(--primary-color);
}

.contact .info .items-holder .item .right{
    font-size: 1.1em;
}

.contact .info .items-holder .item .right span:last-child {
    opacity: .7;
    color: #4e50f4;
    font-weight: 600;
}

.contact .img-holder {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
}

.contact .img-holder .main-image {
    position: absolute;
    top: 23%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
}

.contact .img-holder .question {
    position: absolute;
    top: -64%;
    left: 12%;
    width: 19%;
    transform: translate(-50%, -50%) rotate(338deg);
    animation: fadeIn 2s ease-in-out infinite alternate;
}


.screen{
    position: absolute;
    top: 15%;
    left: 40.3%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255,.5);
    height: 57%;
    aspect-ratio: 76 / 69;
    clip-path: polygon(1% 2%, 94% 2.6%, 98.2% 96%, 4% 96%);
    user-select: none;
    pointer-events: none;
}

.screen-btn{
    position: absolute;
    transform: translate(-50%, -50%);
    top: 46%;
    left: 29%;
    height: 20px;
    width: 10px;
    background: transparent;
    cursor: pointer;
}

@keyframes fadeIn {
    from {
        opacity: .1;
    }
    to {
        opacity: 1;
    }
}

@media (max-width: 1450px){
    .screen{
        top: 14%;
        height: 63%;
    }
    .screen-btn{
        top: 47.5%;
    }
}

@media (max-width: 1350px){
    .screen{
       height: 55%;
    }
}


@media (max-width: 1222px){

    .screen{
       display: none;
     }
    .screen-btn{
        top: 41%;
    }
}

@media (max-width: 1100px){
    .contact {
        display: flex;
        flex-direction: column-reverse;
        border-radius: 1000px 1000px 2px 2px;
        padding: 8em 0 4em 0;
        margin-top: 18em;
        margin-bottom: 5em;
        padding-top: 22em;
    }

    .contact .info {
        padding: 0 4em;
    }
    
    .contact .info h2{
        font-size: 3em;
    }

    .contact .img-holder {
        position: relative;
        display: flex;
        width: 100%;
       justify-content: center;
       align-items: center;
    }

    .contact .img-holder .main-image {
        position: absolute;
        top: unset;
        max-width:44em;
    }

    .contact .img-holder .question {
        top: calc(100% - 27em);
    }
    .screen-btn{
        display: none;
    }
    
    
}

@media (max-width: 800px){
    .contact {
        margin-top: 10em;
    }

    .contact .info h2{
        font-size: 2em;
    }
    .contact .info {
        padding: 0 2em;
    }
    .contact .img-holder .question {
        top: calc(100% - 22em);
    }
}