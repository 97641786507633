.cookie-banner{
    --offset: 1em;
    position: fixed;
    display: flex;
    align-items: center;
    bottom: var(--offset);
    left: var(--offset);
    padding: 1.5em;
    background: #fff;
    z-index: 99;
    border-radius: 1em;
    gap: 2em;
    max-width: 1000px;
    box-shadow: 0 3px 11px rgb(53 49 72 / 9%);
}


.cookie-banner .ico-holder{
    --size: 4em;
    min-width: var(--size);
    min-height: var(--size);
    display: flex;
    background: var(--secondary-color-light);
    /* background: red;     */
    border-radius: 50%;
}

.cookie-banner .ico-holder img{
    display: block;
    margin: auto;
    width: 2em;
}

.cookie-banner .text p{
    font-size: 1.1em;
}

.cookie-banner .text a{
   border-bottom: 2px solid #a09b9e;
   line-height: .5;
}

.cookie-banner .text a:hover{
    color: var(--secondary-color);
    border-color: var(--secondary-color);
    transition: 300ms;
}

.cookie-banner .button-holder a{
    background: var(--secondary-color-light);
}

@media (max-width: 800px){
    .cookie-banner{
        --offset: 0;
        flex-wrap: wrap;
        justify-content: center;
    }
}