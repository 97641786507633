header{
	height: 100px;
	display: flex;
	align-items: center;
	padding: 0 7rem;
	justify-content: space-between;
	position: sticky;
	top: 0;
	z-index: 1000;
	transition: 300ms ease-out;
}

header.hidden{
	transform: translateY(-110px);
	
}

header.visible{
	transform: translateY(0);
}

header nav{
	display: flex;
	align-items: center;
}

nav ul{
	display: flex;
	margin-left: 3em;
}

nav ul li{
    font-size: 1.2em;
	list-style: none;
	margin-right: 2em;
}

nav ul li a{
	border-bottom: 2px solid transparent;
}

nav ul li.active a{
	/* color: var(--primary-color); */
	border-color: var(--primary-color);
	user-select: none;
	font-weight: 500;
	transition: 300ms;
}

.hamburger{
	display: none;
}

.mobile-nav{
	background: var(--body-color);
	position: fixed;
	bottom: 0;
	top: 100px;
	left: 0;
	right: 0;
	z-index: 100;
	opacity: 0;
	animation: appear 300ms;
	animation-fill-mode: forwards;
}

@keyframes appear {
	to{
		opacity: 1;
	}
}

.mobile-nav{
	padding: 20px;
	display: flex;
	flex-direction: column;
}

.mobile-nav ul li{
	font-size: 3em;
}

.mobile-nav .button-holder{
	margin-top: auto;
	font-size: 1.2em;
}

@media (max-width: 1000px){

	.hamburger{
		display: block;
	}

	.navigation{
		display: none;
	}

	header .button-holder{
		display: none;
	}
}