.project-result{
    position: relative;
    background: rgba(var(--project-color-rgb), .15);
    padding: 5em 0;
    overflow: hidden;
}

.project-result::before{
    content: "";
    position: absolute;
    inset: 0;
    background: radial-gradient(circle at 50%, #efeeff 0% 40%, #f5f4ff 30% 55%, #fbfbff 55% 70%, #fff 70% 100%);
    z-index: -1;
}

.project-result .funtenjer{
    max-width: 1100px;
}

.project-result .section-description{
    text-align: center;
    margin-bottom: 2em;
}

.project-result .section-description p{
    opacity: .7;
    margin-top: 1em;
}

.project-result .project{
    display: flex;
}

.project-result .project img{
    max-width: 100%;
    margin: auto;
    border-radius: 1em;
    border: 10px solid rgba(var(--project-color-rgb), .3)
}