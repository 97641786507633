/* ------- SLIDER GLOBAL -------------- */
.slider-holder .slider{
    position: relative;
    background: rgb(var(--rgb-color-light));
    border-radius: 3em;
    margin-bottom: 10em;
    padding: 10em 5em;
    display: flex;
    border: 4px solid transparent;
    animation: pulse-animation 3s linear infinite;
}

.slider-holder .slider.special{
    border-radius: 0;
}

@keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(var(--rgb-color-light), .5);
    }
    100% {
      box-shadow: 0 0 0 80px rgba(var(--rgb-color-light), 0);
    }
  }

.slider-holder .slider .grid{
    margin: auto;
    display: grid;
    grid-template: 1fr / 1fr 1.6fr;
    grid-gap: 10vh;
    align-items: center;
}

.slider-holder .slider .grid .about .back-btn{
    position: fixed;
    top: 2em;
    left: -.3em;
    padding-left: 4em;
    border-radius: 0 3em 3em 0;
    z-index: 99;
}

.slide-left{
    opacity: 1;
    transition: 500ms ease-out;
}

.slide-left.hidden{
   opacity: 0;
}

.slider-holder .slider .grid .about h2{
    margin-bottom: 1em;
    font-weight: 500;
    font-size: 3em;
}

.slider-holder .slider .grid .about .button-holder{
    margin-top: 2em;
}

/* ------- eLibrary SLIDER SPECIFIC -------------- */

.slider-holder .slider .grid .mockup .img-holder {
    --border-radius: 1em;
    position: relative;
    background: var(--main-font-color);
    background: #242333;
    padding: 1em;
    z-index: 1;
    border-radius: var(--border-radius);

}

.slider-holder .slider .grid .mockup .img-holder::before{
    content: "";
    z-index: -1;
    position: absolute;
    inset: 0;
    opacity: 0.2;
    background-image:  linear-gradient(#444cf7 1px, transparent 1px), linear-gradient(to right, #444cf7 1px, transparent 1px);
    background-size: 30px 30px;
    border-radius: var(--border-radius);
}

.slider-holder .slider .grid .mockup .img-holder img {
    display: block;
    max-width: 100%;
}

.slider-holder .slider .grid .mockup .nav{
    position: absolute;
    right: 0;
    bottom: -1em;
    display: flex;
}

.slider-holder .slider .grid .mockup .nav .ico{
    --size: 3em;
    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: .5em;
    margin-right: 1em;
    color: rgb(var(--rgb-color));
    box-shadow: 0 3px 11px rgb(53 49 72 / 9%);
    cursor: pointer;
    transition: .5s;
}

.slider-holder .slider .grid .mockup .nav .ico:hover{
    background: rgb(var(--rgb-color));
    color: var(--main-font-color);
}

.slider-holder .slider .grid .mockup .nav .ico.active{
    transform: translateY(-5px);
    transition: .5s;
}

@media (max-width: 1350px){
    .slider-holder .slider{
        margin-bottom: 5em;
        border-radius: 0;
        padding-inline: 3em;
    }
}

@media (max-width: 1000px){
    .slider-holder .slider{
        padding: 5em 1em;
    }
    .slider-holder .slider .grid{
        display: flex;
        flex-direction: column;
    }

    .slider-holder .slider.special{
        padding-top: 8em;
    }
}

@media (max-width: 800px){
    .slider-holder .slider .grid .about h2 {
        font-size: 2.3em;
        font-weight: 600;
        word-wrap: break-word;
        text-align: center;
    }
}
