.privacy-policy .header{
    padding: 4em;
    background: var(--secondary-color-light);
    border-radius: 3em;
    margin-bottom: 2em;
}

.privacy-policy h2{
    margin-bottom: .6em;
}
.privacy-policy p{
    margin-bottom: 1em;
}

.privacy-policy ul{
    padding-left: 1em;
    list-style: disc;
}

.privacy-policy ul li{
    margin-bottom: .1em;
}

.privacy-policy .content{
    padding: 0 4em;
}


@media (max-width: 1000px){
	.privacy-policy{
		font-size: 80%;
	}
    .privacy-policy .content{
		padding: 0;
	}
}