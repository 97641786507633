.about-page{
    overflow: hidden;
}

.about-hero{
    position: relative;
    margin: 4rem 0 0 0;
    font-size: 2rem;
    padding-bottom: 12em;
}

.about-hero .line{
    position: absolute;
    top: -11em;
    left: -50%;
    z-index: 1;

}

.about-hero h3{
    font-size: 1.2em;
}

.about-hero h1{
    font-size: 5em;
    display: flex;
    flex-wrap: wrap;
}

.about-hero h1 span{
    position: relative;
    z-index: 2;
}

.company-mission{
    position: relative;
    margin: 0em 0 7em 0;
}

.company-mission h2 span{
    color: var(--primary-color);
    background: var(--primary-color-light);
    margin-bottom: .2em;
    font-size: .5em;
    font-family: var(--popins);
    text-transform: uppercase;
    display: inline-block;
    padding: .3em 1em;
    border-radius: 1000px;
}

.company-mission p{
    font-size: 3em;
    line-height: 1.4;
    opacity: .9;
    font-family: var(--hepta);
    margin-bottom: 1em;
}

.we-are p{
    width: 80%;
}

.we-are .grid{
    display: flex;
    /* flex-wrap: wrap; */
    gap: 15em 2em;
    margin-top: 10em;
    justify-content: space-between;
}

.person .img-holder img{
    width: 20em;
    max-width: 100%;
    border-radius: 1000px;
    object-fit: cover;
    filter: grayscale(95%);
}

.person .info{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.2rem;
    margin-top: 1.4em;
}

.person .info .title{
    color: var(--primary-color)
}

.person .info .socials{
    display: flex;
    gap: .3em;
}

.person .info .socials .ico{
    --size: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary-color);
    color: #fff;
    height: var(--size);
    width: var(--size);
    border-radius: 50%;
}

.we-are h2{
    margin-bottom: .5em;
}

.about-cards{
    margin: 10em 0 30em 0;
}

.about-cards .card:first-child{
    --rgb: var(--secondary-color-rgb);
    /* border-radius: 3em 3em 0 0; */
}


.about-cards .card:last-child{
    --rgb: var(--primary-color-rgb);
    text-align: right;
    padding-left: 20em;
    /* border-radius: 0 0 3em 3em; */
}

.about-cards div:last-child p{
    width: 100%;
}

.about-cards div:last-child svg{
    left: 0em;
}

.about-cards .card{
    position: relative;
    padding: 10em;
    background: rgba(var(--rgb), .1);
    border-radius: 3em;
    margin-bottom: 5em;
}

.about-cards .card h2{
    color: rgb(var(--rgb));
    margin-bottom: .5em;
}

.about-cards .card p{
    width: 80%;
    text-align: justify;
}

.about-cards .card svg{
    position: absolute;
    right: 0em;
    top: 0;
    font-size: 20em;
    color: rgba(var(--rgb), .3);
}

.about-cards .card:first-child img{
    position: absolute;
    right: -20%;
    top: -30%;
    transform: rotate(18deg);
    width: 45em;
}

.about-cards .card:last-child img{
    position: absolute;
    left: 1em;
    top: 1em;
    transform: rotate(18deg);
    width: 15em;
}

@media (max-width: 1084px){
.about-hero {
    padding-bottom: 10em;
}

.about-hero h3{
    font-size: 1em;
}

.about-hero h1{
    position: relative;
    font-size: 4em;
    z-index: 2;
}

.about-hero .line{
    width: 80em;
    left: -80%;
}

.we-are{
    margin-bottom: 31em;
}

.we-are .grid{
    flex-wrap: wrap;
}

}

@media (max-width: 845px){
    .about-hero {
        padding-bottom: 7em;
    }

    .about-hero h3{
        font-size: .8em;
    }
    
    .about-hero h1{
        font-size: 3em;
    }
    
    .about-hero .line{
        width: 60em;
        top: -7em;
        left: -80%;    
    }

    .company-mission{
        margin-bottom: 3em;
      }
      
    .company-mission p{
        font-size: 2em;
      }

    .we-are{
        margin-bottom: 21em;
    }

    .we-are .grid {
        margin-top: 3em;
        justify-content: center;
        gap: 6em 2em;
        flex-direction: column-reverse;
        align-items: center;
    }

    .we-are .grid .person{
        margin-top: 0 !important;
    }
      
    .we-are p{
        width: auto;
    }
    
}

@media (max-width: 604px){
    .about-hero {
        padding-bottom: 5em;
    }

    .about-hero h1{
        font-size: 2.5em;
    }
    
    .about-hero .line{
        width: 50em;
        top: -5em;
        left: -100%;    
    }
    
    }
    
    
