.hero{
	margin-bottom: 5em;
	overflow: hidden;
	padding-bottom: 5em;
}

.hero .grid{
	margin-top: 7em;
	display: grid;
	grid-template: 1fr / 1fr 1fr;
	grid-gap: 1em;
	align-items: center;
}

.hero .grid .info{
	position: relative;
}

.hero .grid .info h1{
	font-weight: 500;
}

.hero .grid .info p{
	margin-top: 1.2em;
	font-size: 1.625em;
}

.hero .grid .info .button-holder{
	margin-top: 1.5em;
}

.hero .grid .info .picture{
	position: absolute;
	right: -2em;
	bottom: -14em;
}

.hero .grid .info .picture img{
	height: 16.8125em;
}

/* // Presentation */

.hero .grid .presentation{
	display: flex;
	align-items: center;
	justify-content: center;
}

.hero .grid .presentation .slide{
	width: 19.125em;
	height: 31.125em;
	border-radius: 1000px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.hero .grid .presentation .slide img{
	opacity: .8;
	width: 77%;
}

.hero .grid .presentation .slide:first-child{
	background: var(--primary-color-light);
	margin-top: -5em;
	animation: float 7s ease-in-out infinite;
}

.hero .grid .presentation .slide:last-child{
	background: var(--secondary-color-light);
	margin-bottom: -8em;
	margin-left: -2em;
	animation: float 9s ease-in-out infinite;
}

/* // Rolling content */

.hero .big-funtenjer{
	margin-bottom: 14em;
}

.roll-content{
	font-size: 1.5em;
	transform: rotate(-3deg);
}

.roll-content a{
	font-weight: 500;
	pointer-events: none;
}

/* // Animations */

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-15px);
	}
	100% {
		transform: translatey(0px);
	}
}

@media (max-width: 1050px){
	.hero .grid{
		display: flex;
		flex-direction: column-reverse;
	}

	.hero .grid .info{
		text-align: center;
	}

	.hero .grid .info h1{
		position: relative;
		font-size: 4em;
		z-index: 1;
	}

	.roll-content{
		font-size: 1.3em;
	}
	
}

@media (max-width: 800px){
	.hero .grid{
		margin-top: 2em;
	}

	.hero .grid .info{
		text-align: left;
	}

	.hero .grid .info h1{
		font-size: 3em;
	}
	.hero .grid .presentation .slide{
		display: none;
	}

	.hero .grid .info .picture{
		right: -3em;
		bottom: -15em;
	}

	.hero .big-funtenjer{
		margin-bottom: 17em;
	}

	.roll-content{
		font-size: 1em;
	}
}