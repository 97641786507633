@import url('https://fonts.googleapis.com/css2?family=Hepta+Slab:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* // Restart css */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure, 
footer, hgroup, menu, nav, section {
	display: block;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

h1{
	font-size: 4.5em;
	font-weight: 400;
	font-family: var(--hepta);
	line-height: 1.2;
}

h2{
    font-size: 2.7em;
    font-weight: 600;
    font-family: var(--hepta);
}

p{
    font-size: 1.3em;
}

/* END */

::-moz-selection { /* Code for Firefox */
	background: yellow;
  }
  
  ::selection {
	background: yellow;
  }

* {
	/* outline: none; */
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
	scroll-margin: 10em;
}

a {
	text-decoration: none;
	-moz-text-decoration: none;
	-moz-text-decoration: none;
	color: inherit;
}

:root {
	--main-font-color-rgb: 11, 30, 91;
	--main-font-color: rgb(var(--main-font-color-rgb));
	--body-color: rgb(247, 248, 242);
	
	--primary-color-rgb: 140, 175, 49;
	/* --primary-color-rgb: #8caf31; */
	
	--primary-color: rgb(var(--primary-color-rgb));
	--primary-color-light: rgba(var(--primary-color-rgb), .1);
	
	--primary-color-light-rgb: 239, 241, 229;
	
	--secondary-color: #8f74f7;
	--secondary-color-rgb: 143, 116, 247;

	--secondary-color-light: #e7e7fd;
	--secondary-color-light-rgb: 231, 231, 253;
	
	/* font */
	--hepta:'Hepta Slab', serif;
	--popins: 'Poppins', sans-serif;
}

html{
	height: 100%;
	scroll-behavior: smooth;
}

body {
	margin: 0;
	position: relative;
	height: 100%;
	font-family: var(--popins);
	background: var(--body-color);
	color: var(--main-font-color);
	overflow-x: hidden;
}


img{
	user-select: none;
  	pointer-events: none;
}

/* ////// Logo */

.logo{
	position: relative;
	display: block;
	/* font-size: 1.5em; */
}

.logo img{
  height: 2em;
  user-select: unset;
  pointer-events: unset;
}

.big-funtenjer{
	max-width: 1600px;
	margin: 0 auto;
	padding: 0 40px;
}

.funtenjer {
	max-width: 1400px;
	margin: 0 auto;
	padding: 0 30px;
}

.min-funtenjer{
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 30px;
}

/* // Buttons */

.back-btn,
.secondary-light-btn,
.secondary-btn,
.primary-border-btn,
.primary-light-btn,
.primary-btn{
	position: relative;
	display: inline-block;
	border: none;
	padding: 1.2em 2.2em;
	border-radius: 1000px;
	font-size: 1em;
	cursor: pointer;
	transition: all 300ms ease-in-out;
	font-weight: 600;
	overflow: hidden;
	z-index: 1;
}

.secondary-btn:active,
.primary-btn:active{
	color: #fff;
	transition: 200ms;
}

.secondary-btn:active::after,
.primary-btn:active::after{
	--background: var(--main-font-color);
}

.secondary-light-btn::after,
.primary-light-btn::after,
.secondary-btn::after,
.primary-btn::after{
	--size: .5em;
	--background: rgba(255,255,255,.3);
	content: "";
	position: absolute;
	inset: 0;
	margin: auto;
	background: var(--background);
	border-radius: 50%;
	width: var(--size);
	height: var(--size);
	opacity: 0;
	transition: 300ms ease;
	z-index: -1;
}

.secondary-light-btn::after,
.primary-light-btn::after{
	--background: rgba(0,0,0,.1);
}

.secondary-light-btn:hover::after,
.primary-light-btn:hover::after,
.secondary-btn:hover::after,
.primary-btn:hover::after {
    transform: scale(30);
	transition: 300ms ease-in-out;
	opacity: 1;
}

.back-btn:hover{
	color: #fff;
	background: var(--main-font-color);
}

.back-btn:active{
	color: #fff;
	background:rgba(var(--main-font-color-rgb), .8);
	transition: 100ms;
}

.primary-btn{
	background: rgba(var(--primary-color-rgb), .4);
}

.primary-border-btn{
	border: 2px solid var(--primary-color);
	position: relative;
	overflow: hidden;
}

.primary-border-btn:hover{
	background: rgb(var(--primary-color-rgb), .4);
}

.primary-border-btn:active{
	background: rgb(var(--primary-color-rgb), 1);
}

.primary-border-btn::before{
	content: "";
	position: absolute;
	inset: 0;
	background: var(--body-color);
	z-index: -1;
	filter: blur(20px);
}

.primary-light-btn{
	background: var(--primary-color-light);	
	background: rgba(var(--primary-color-rgb), .3);
}

.secondary-btn{
	background: rgba(var(--secondary-color-rgb), .4);
}

.secondary-light-btn{
	background: var(--secondary-color-light);
}

.back-btn{
	background: #fff;
	display: inline-flex;
	align-items: center;
}

.back-btn svg{
	font-size: 1.3em;
	margin-right: .4em;
}

@media (max-width: 1450px){
	body{
		font-size: 90%;
	}
}

@media (max-width: 800px){
	.min-funtenjer,
	.funtenjer,
	.big-funtenjer{
		padding: 0 20px;
	}
}