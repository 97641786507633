.page-not-found{
    margin: 15em 0;
}

.page-not-found .content{
    display: flex;
    gap: 1em;
}

.page-not-found .content > div{
    flex: 1;
}

.page-not-found .content h2{
    margin-bottom: 1em;
}

.page-not-found .content .primary-light-btn{
    margin-top: 1em;
}

.page-not-found .roll-content{
    margin-top: 10em;
}

.page-not-found .img-holder {
    background: var(--primary-color-light);
    position: relative;
    display: flex;
    border-radius: 10000px;
    z-index: -1;
}

.page-not-found .img-holder .main-image {
    position: absolute;
    top: 23%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
}

@keyframes fadeIn {
    from {
        opacity: .1;
    }
    to {
        opacity: 1;
    }
}

@media (max-width: 1000px){
    .page-not-found .content{
        display: flex;
        flex-direction: column-reverse;
    }
    .page-not-found .img-holder .main-image{
        opacity: .2;
    }
    .page-not-found .roll-content{
        font-size: .6em;
        overflow: hidden;
    }
}