.technology-stack{
  margin: 10em 0;
}

.technology-stack .header{
    text-align: center;
}

.technology-stack .header p{
    margin-top: 1em;
    opacity: .6;
}

.technology-stack .grid {
    display: flex;
    flex-wrap: wrap;
    max-width: 1100px;
    margin: 4em auto;
    gap: 2em;
  }

.technology{
    position: relative;
    height: 100%;
    background: rgba(var(--project-color-rgb), .15);
    text-align: center;
    padding: 3em 7em;
    border-radius: 2em;
    flex-grow: 1;
    overflow: hidden;
}

.technology svg{
    font-size: 8em;
    fill: rgba(var(--project-color-rgb), .3);
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(330deg);
}

.technology .title{
    font-weight: 600;
    font-size: 1.2em;
}

.technology .name{
    opacity: .7;
}
