.project-features{
    --background: rgba(var(--project-color-rgb), .15);
    position: relative;
    background: var(--background);
    padding-top: 5em;
}

.project-features::before{
    content: "";
    position: absolute;
    top: -11em;
    left: -10em;
    right: 0;
    bottom: 100vh;
    background-image: url("./../../assets/line.png");
    background-size: cover;
    opacity: .9;
}

.project-features .about-features{
    max-width: 900px;
    margin: 0 auto;
    text-align: center;
}

.project-features .about-features p{
    opacity: .7;
    margin-top: 1em;
}

.project-features h2{
    /* position: relative; */
    font-size: 4em;
}


.project-features .funtenjer{
    max-width: 1100px;
}

.project-features::after{
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 50em;
    background: linear-gradient(0deg, transparent, var(--background));
    z-index: -1;    
}

.project-features .feature:nth-child(2n){
    flex-direction: row-reverse;
}

.project-features .feature:nth-child(2n) .img-holder{
    justify-content: flex-start;
}

.project-features .feature:nth-child(2n) .img-holder img{
  margin-left: -1.5em;
}

.project-features .feature{
    display: flex;
    padding: 5em 0;
}

.project-features .feature .info{
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    margin: auto;
    /* align-items: center; */
    flex-direction: column;
    padding: 0 5em;
}


.project-features .feature .info h3{
    font-size: 3em;
    font-family: var(--popins)
}


.project-features .feature .info p{
    margin-top: 1em;
}

.project-features .feature .img-holder{
    flex: 2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.project-features .feature .img-holder img{
    max-width: 100%;
    transform: rotate(1deg);
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    border-radius: 1.5em;
    margin-right: -1em;
    border: 15px solid rgba(var(--project-color-rgb), .2);
}

.project-features .feature:nth-child(2n) .img-holder img{
    transform: rotate(-1deg);
}


.project-image{
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    z-index: 99;
}

.ios.project-image {
    background-attachment: scroll;
}


@media (max-width: 800px){

    .project-features{
        overflow: hidden;
    }

    .project-features h2{
        font-size: 3em;
        padding: 0 20px;
    }

    .project-features .about-features{
        position: relative;
        padding-bottom: 4em;
    }

    .project-features .about-features::before{
        content: "";
        position: absolute;
        bottom: -1em;
        left: -20em;
        right: 0;
        top: 0;
        background-image: url("../../assets/aboutLine.png");
        background-size: cover;
    }

    .project-features .about-features p{
        padding: 0 20px;
    }

    .project-features::before{
        display: none;
    }

    .project-features .feature:nth-child(1n){
        flex-direction: column;
        gap: 2em;
        padding: 0;
    }

    .project-features .feature .info {
        padding: 20px;
    }

    .project-features .feature .info h3{
        font-size: 2em;
    }

    .project-features .feature .img-holder{
        justify-content: center;
        align-items: center;
    }
    .project-features .feature .img-holder img{
        margin-right: 0;
    }
    
}